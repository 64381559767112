import   React            ,
       { useEffect        ,
         useState         }  from 'react';

import { Link             ,
         useNavigate      }  from 'react-router-dom';

import   classNames          from 'classnames';

import   styles              from './part_01.module.scss';

import { WindowWithForm     }  from '../../../../../widget/window/window_with_form';


import { useTypedSelector }  from '../../../../../../datas/useTypeSelector';

import { postEvent        }  from '../../../../../../network/api_events_stats';
import { postInformationRequest }  from '../../../../../../network/api_urban_tour';

import { INTERNAL_LINKS   }  from '../../../../../../network/urls';

export const Part01 = () => {

  const currentUrbanTour: string = "Lovelight Urban Tour spécial Rome"

  const { authentication } = useTypedSelector((state) => state.authenticationManagment );

 const [ alertInformationRequestWindowVisible , setAlertInformationRequestWindowVisible ] = useState ( false );

/*
  const handleInformationRequest = () => {

    const eventMessage: string = "UrbanTour-Rome-Part01-Button01:\"Demander la brochure\""

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertInformationRequestWindowVisible ( true );
  }

  const closeAlertInformationRequestWindowWithCancelButton = () => {

    setAlertInformationRequestWindowVisible ( false );
  }

  const closeAlertInformationRequestWindowWithValidButton = ( firstname: string ,
                                                              lastname:  string ,
                                                              age:       number ,
                                                              email:     string ,
                                                              city:      string ) => {

    setAlertInformationRequestWindowVisible ( false );

    postInformationRequest ( firstname, lastname, age, email, city, `${currentUrbanTour}` )
  }
*/
   return (

        <div className={classNames(styles.part1)}>

 {/*
	    alertInformationRequestWindowVisible &&
	    ( < WindowWithForm  message               = { <><span className = {classNames(styles.bold_text)}>Je souhaite recevoir par mail des information concernant l'offre "{currentUrbanTour}".</span><br/></> }
                            messageType           = "normal"
                            onClickOnCancelButton = {closeAlertInformationRequestWindowWithCancelButton}
                            onClickOnValidButton  = {closeAlertInformationRequestWindowWithValidButton}
                            cancelButtonName      = "NON"
                            validButtonName       = "OUI"
							stylesGridInfo        = {classNames(styles.grid_info)} /> )
	  */}
	  
          <div className="w-full rounded-md flex md:justify-center relative overflow-hidden">

            <div className="p-4 max-w-9xl  mx-auto relative z-10  w-full pt-20 md:pt-20">

              <h1 className="text-4xl md:text-7xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 bg-opacity-50">
                Lovelight Urban Tour<br/>Rome
              </h1>

  		      <p className="mt-4 font-normal text-4xl text-neutral-300 max-w-lg text-center mx-auto">
                Shooting en lumière naturelle dans la ville éternelle
              </p>

		      <br/>
			  {/*
                  <div className={classNames(styles.btn)}>

	               <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
		                   onClick   = {handleInformationRequest}>
                     <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                     <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-3 py-1 text-xl font-medium text-white backdrop-blur-3xl">
                       Demander la brochure
                     </span>
                   </button>
				   
                  </div>
              */}
            </div>

          </div>

        </div>
   );

};

export default Part01;

