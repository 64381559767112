import   axios                       from 'axios';

import { getUserAgentInformations ,
         getAppInformations       }  from './utils';	

import { API_URLS                 }  from './urls';

export interface PostInformationRequestResult {
  code:    number,
  comment: string
}

export const postInformationRequest = async ( firstname:   string ,
                                              lastname:    string ,
                                              age:         number ,
                                              email:       string ,
                                              phoneNumber: string ,
                                              city:        string ,
                                              subject:     string ): Promise<PostInformationRequestResult> => {

    console.log ( "postInformationRequest " , firstname, lastname, email, phoneNumber, city );

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();
	
    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json',
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };
	
	var result:PostInformationRequestResult = {
      code:    0,
      comment: "",
    };
		
    try {

        const response = await axios.post (
        
                                            `${API_URLS.postInformationRequest}`,
                              
                                            { 
                                              firstname   ,
                                              lastname    ,
                                              age         ,
                                              email       ,
											  phoneNumber ,
                                              city        ,
											  subject                  											 
                                            },
                                            
                                            {
                                              headers: headers
                                            }
                                          );

        console.log ( "postInformationRequest " , response );

		if ( response.status === 201 )
		      { 
			   result = {
		         code:            1 ,
		         comment: "Demande d'information envoyée" ,
		       };
			  }
		 else {
			   result = {
		         code:     -101 ,
		         comment: `Erreur lors de la création de la demande d'information ${response.status}`,
		       };
			  }

    } 
    catch (error) {

        console.log ( "postInformationRequest " , error );

        result = {
		  code:    -201,
		  comment: "Erreur réseau",
		}; 
    }

	return result;
};


export interface PostUrbanTourRegistrationResult {
  code:    number,
  comment: string
}

export const postUrbanTourRegistration = async ( destination                : string  ,
                                                 sessionDates               : string  ,
	                                             email                      : string  ,
	                                             firstname                  : string  ,   
	                                             lastname                   : string  ,
	                                             birthday                   : string  ,
	                                             address                    : string  ,
	                                             address2                   : string  ,
	                                             zipCode                    : string  ,
	                                             city                       : string  ,
	                                             country                    : string  ,
	                                             phoneNumber                : string  ,
	                                             identityDocument           : string  ,
	                                             identityDocumentValidity   : string  ,
	                                             nationality                : string  ,
	                                             accompanyingPersonPresence : boolean ,
	                                             airTransport               : boolean ,
	                                             departureAirport           : string  ,
	                                             baggageToBeCheckedIn       : boolean ,
	                                             largeCarryOnBaggage        : boolean ,
	                                             airportTransfer            : boolean ,
	                                             carRental                  : boolean ,
	                                             contactRequest             : boolean ,
	                                             additionalInformation      : string  ): Promise<PostUrbanTourRegistrationResult> => {


	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();
	
    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json',
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };
	
	var result:PostUrbanTourRegistrationResult = {
      code:    0,
      comment: "",
    };
		
    try {

        console.log ( "postUrbanTourRegistration ", `${API_URLS.postUrbanTourRegistration}` );

        const response = await axios.post (
        
                                            `${API_URLS.postUrbanTourRegistration}`,
                              
                                            { 
                                              destination                ,
	                                          sessionDates               ,
	                                          email                      ,
	                                          firstname                  ,   
	                                          lastname                   ,
	                                          birthday                   ,
	                                          address                    ,
	                                          address2                   ,
	                                          zipCode                    ,
	                                          city                       ,
	                                          country                    ,
	                                          phoneNumber                ,
	                                          identityDocument           ,
	                                          identityDocumentValidity   ,
	                                          nationality                ,
	                                          accompanyingPersonPresence ,
	                                          airTransport               ,
	                                          departureAirport           ,
	                                          baggageToBeCheckedIn       ,
	                                          largeCarryOnBaggage        ,
	                                          airportTransfer            ,
	                                          carRental                  ,
	                                          contactRequest             ,
	                                          additionalInformation                          											 
                                            },
                                            
                                            {
                                              headers: headers
                                            }
                                          );

        console.log ( "postUrbanTourRegistration " , response );

		if ( response.status === 201 )
		      { 
			   result = {
		         code:            1 ,
		         comment: "Demande d'information envoyée" ,
		       };
			  }
		 else {
			   result = {
		         code:     -101 ,
		         comment: `Erreur lors de l'enregistrement à l'urban tour ${response.status}`,
		       };
			  }

    } 
    catch (error) {

        console.log ( "postUrbanTourRegistration " , error );

        result = {
		  code:    -201,
		  comment: "Erreur réseau",
		}; 
    }

	return result;
};
