import { differenceInYears ,
         parse             , 
         isValid           } from 'date-fns';
	 
import { getUserExistenceCheck }  from '../../network/api_user';
		 
export interface Validation
 {
  code:    number  ,
  comment: string
 }
 
export const checkEmail = async ( newEmail: string ): Promise<Validation> => {

  var result: Validation = {
    code:    1 ,
    comment: ""  
  };


  if ( newEmail === "" )
        {
	     result.code    = -1;
		 result.comment = "Il manque l'adresse e-mail.";

        }
   else {
         const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        
         if ( emailRegex.test (newEmail) === false )
		       {
                result.code = -2;
                result.comment = "Ce n'est pas une adresse email valide.";
				

			   }
          else {
                const userExistenceCheckResult = await getUserExistenceCheck ( "email" , newEmail );
      

                if ( userExistenceCheckResult.code === 1 ) 
                 {
                  result.code = -3;
		          result.comment = "Un compte existe déjà avec cet e-mail.";
                 }
			   }
        }
		  
  return result;
};

export const checkEmail2 = ( email: string ): Validation => {

  var result: Validation = {
    code:    1 ,
    comment: ""  
  };


  if ( email === "" )
        {
	     result.code    = -1;
		 result.comment = "Il manque l'adresse e-mail.";

        }
   else {
         const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        
         if ( emailRegex.test (email) === false )
		       {
                result.code = -2;
                result.comment = "Ce n'est pas une adresse email valide.";
			   }
        }
		  
  return result;
};

export const checkNewEmail = async ( newNewEmail: string ): Promise<Validation> => {

  return await checkEmail (newNewEmail);
};

export const checkPassword = ( newPassword: string ): Validation => {

  var result: Validation = {
    code:    1 ,
    comment: ""  
  };

  if ( newPassword === "" )
        {
	     result.code    = -1;
		 result.comment = "Il manque le mot de passe.";
        }
   else {
         if ( newPassword.length > 0 && newPassword.length < 8) 
          {
           result.code = -2;
		   result.comment = "Le mot de passe fait moins de 8 caractères.";
          }
        }        

  return result;
};

export const checkPassword2 = ( newPassword2: string ,
                                password:     string ): Validation => {

  var result: Validation = {
    code:    1 ,
    comment: ""  
  };

  if ( newPassword2 !== password ) 
   {
    result.code    = -1;
    result.comment = "Les deux mots de passe sont différents.";
   }

  return result;
};

export const checkFirstname = ( newFirstname: string ): Validation => {

  var result: Validation = {
    code:    1 ,
    comment: ""  
  };

  if ( newFirstname === "" )
   {
    result.code    = -1;
    result.comment = "Il manque le prénom.";   
   }
	 
  return result;
};

export const checkLastname = ( newLastname: string ): Validation => {

  var result: Validation = {
    code:    1 ,
    comment: ""  
  };

  if ( newLastname === "" )
   {
    result.code    = -1;
    result.comment = "Il manque le nom.";   
   }
		  
  return result;
};

export const checkSex = ( newSex: string ): Validation => {

  var result: Validation = {
    code:    1 ,
    comment: ""  
  };

  if ( newSex === "" )
   {
    result.code    = -1;
    result.comment = "Il manque le sexe.";  
   }
		  
  return result;
};

export const checkBirthday = ( newBirthday: string ): Validation => {

  var result: Validation = {
    code:    1 ,
    comment: ""  
  };

  if ( newBirthday === "" )
        {
         result.code    = -1;
         result.comment = "Il manque la date de naissance.";  
        }

   else {
         const parsedDate = parse ( newBirthday, 'dd/MM/yyyy', new Date() );
        
         if ( isValid (parsedDate) === false )
		       {
                result.code    = -2;
                result.comment = "La date saisie n'est pas valide.";  
			   }
          else {
                const birthdayDate            = new Date ( newBirthday );
                const currentDate             = new Date ();
                const differenceInYearsResult = differenceInYears ( currentDate, birthdayDate );

                if ( differenceInYearsResult < 18 )
                 {
                  result.code    = -3;
                  result.comment = "Il faut être âgé d'au moins 18 ans.";  
                 }
               }
        }
		  
  return result;
};

export const checkAddress = ( newAddress: string ): Validation => {

  var result: Validation = {
    code:    1 ,
    comment: ""  
  };

  if ( newAddress === "" )
   {
    result.code    = -1;
    result.comment = "Il manque l'adresse.";  
   }

  return result;
};

export const checkAddress2 = ( newAddress2: string ): Validation => {

  var result: Validation = {
    code:    1 ,
    comment: ""  
  };

  return result;
};
    
export const checkZipCode = ( newZipCode: string ): Validation => {

  var result: Validation = {
    code:    1 ,
    comment: ""  
  };
    
  if ( newZipCode === "" )
   {
    result.code    = -1;
    result.comment = "Il manque le code postal.";  
   }

  return result;
};

export const checkCity = ( newCity: string ): Validation => {

  var result: Validation = {
    code:    1 ,
    comment: ""  
  };
    
  if ( newCity === "" )
   {
    result.code    = -1;
    result.comment = "Il manque la ville.";  
   }

  return result;
};

export const checkCountry = ( newCountry: string ): Validation => {

  var result: Validation = {
    code:    1 ,
    comment: ""  
  };
    
  if ( newCountry === "" )
   {
    result.code    = -1;
    result.comment = "Il manque le pays.";  
   }

  return result;
};

export const checkPhoneNumber =  ( newPhoneNumber: string ,
                                        email:          string ): Validation => {

  var result: Validation = {
    code:    1 ,
    comment: ""  
  };
    
  if ( newPhoneNumber === "" )
        {
         result.code    = -1;
         result.comment = "Il manque le numéro de téléphone.";  
        }
   else {
         const phoneRegex = /^(\+)?(\d{1,3})?[-. ]?(\d{3})[-. ]?(\d{3})[-. ]?(\d{4})$/;
        
         if ( phoneRegex.test (newPhoneNumber) === false )
		       {
                result.code    = -2;
                result.comment = "Ce n'est pas un numéro de téléphone valide.";  
			   }
			   /*
          else {
                const userExistenceCheckResult = await getUserExistenceCheck ( "phoneNumber"  , 
		                                                                       newPhoneNumber ,
				        		                     						   email          );
      
                if ( userExistenceCheckResult.code === 1 ) 
                 {
                  result.code    = -3;
                  result.comment = "Un compte existe déjà avec ce numéro de téléphone.";  
                 }
			   }
			   */
        }
		  
  return result;
};

export const checkPhoneNumber2 =  ( newPhoneNumber: string ,
                                        email:          string ): Validation => {

  var result: Validation = {
    code:    1 ,
    comment: ""  
  };
    
  if ( newPhoneNumber !== "" )
        {
         const phoneRegex = /^(\+)?(\d{1,3})?[-. ]?(\d{3})[-. ]?(\d{3})[-. ]?(\d{4})$/;
        
         if ( phoneRegex.test (newPhoneNumber) === false )
		       {
                result.code    = -2;
                result.comment = "Ce n'est pas un numéro de téléphone valide.";  
			   }
			   /*
          else {
                const userExistenceCheckResult = await getUserExistenceCheck ( "phoneNumber"  , 
		                                                                       newPhoneNumber ,
				        		                     						   email          );
      
                if ( userExistenceCheckResult.code === 1 ) 
                 {
                  result.code    = -3;
                  result.comment = "Un compte existe déjà avec ce numéro de téléphone.";  
                 }
			   }
			   */
        }
		  
  return result;
};

export const checkRgpdConsent = ( newRgpdConsent: boolean ): Validation => {
  
  var result: Validation = {
    code:    1 ,
    comment: ""  
  };
    
  if ( !newRgpdConsent )
   {
    result.code    = -1;
    result.comment = "Validation obligatoire.";  
   }
		  
  return result;
};
