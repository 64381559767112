import   React       ,  
       { useEffect   ,
	     useState    }  from 'react';
		 
import   classNames     from 'classnames';

import   styles         from './window.module.scss';

import { Field       }  from '../field/field';
import { WidgetFrame }  from '../widget_frame/widget_frame';

export interface WindowWithFormProps {
  message:                 JSX.Element
  messageType?:            string
  onClickOnCancelButton?:  (() => void) | undefined;
  onClickOnValidButton?:   ((firstname: string, lastname: string, age: number, email: string, phoneNumber: string, city: string) => void) | undefined;
  closeButtonName?:        string;
  cancelButtonName?:       string;
  validButtonName?:        string;
  stylesGridInfo:          string;
  initialFirstname:        string;
  initialLastname:         string;
  initialAge:              string;
  initialEmail:            string;
  initialPhoneNumber:      string;
  initialCity:             string; 
}

export const WindowWithForm = ({ message               ,
                                 messageType           ,
                                 onClickOnCancelButton ,
						         onClickOnValidButton  ,
						         closeButtonName       ,
                                 cancelButtonName      ,
                                 validButtonName       ,
							     stylesGridInfo        ,
								 initialFirstname      ,
                                 initialLastname       ,
                                 initialAge            ,
                                 initialEmail          ,
								 initialPhoneNumber    ,
                                 initialCity           }: WindowWithFormProps) => {

  const [ firstname                , setFirstname                ] = useState ( initialFirstname );
  const [ lastname                 , setLastname                 ] = useState ( initialLastname );
  const [ age                      , setAge                      ]  = useState ( initialAge );
  const [ email                    , setEmail                    ]  = useState ( initialEmail );
  const [ phoneNumber              , setPhoneNumber              ] = useState ( initialPhoneNumber );
  const [ city                     , setCity                     ] = useState ( initialCity );

  const [ firstname_errorMessage   , setFirstname_errorMessage   ] = useState ( "" );
  const [ lastname_errorMessage    , setLastname_errorMessage    ] = useState ( "" );
  const [ age_errorMessage         , setAge_errorMessage         ] = useState ( "" );
  const [ email_errorMessage       , setEmail_errorMessage       ] = useState ( "" );
  const [ phoneNumber_errorMessage , setPhoneNumber_errorMessage ] = useState ( "" );
  const [ city_errorMessage        , setCity_errorMessage        ] = useState ( "" );

  const [ isSmallScreen            , setIsSmallScreen            ] = useState<boolean>(false);

  const handleResize = () => {
      setIsSmallScreen ( window.innerWidth <= 600 );
  };

  useEffect(() => {

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
	
  }, []);

  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setEmail ( e.target.value )

    if ( e.target.value !== "" )
          {
           if ( emailRegex.test(e.target.value) )
                 {
                  setEmail_errorMessage ("")
                 }  
            else {
                  setEmail_errorMessage ("La saisie est incorrecte")
                 }
		  }
     else {
           setFirstname_errorMessage ("La saisie est obligatoire")
          }
		  
  };

  const handlePhoneNumberChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

  const phoneRegex = /^\+?[0-9]{10,15}$/;

  setPhoneNumber (e.target.value);

  if ( e.target.value !== "" ) 
        {
         if ( phoneRegex.test(e.target.value) )
   		       {
                setPhoneNumber_errorMessage ("");
               } 
		  else {
                setPhoneNumber_errorMessage ("Le numéro de téléphone est incorrect");
               }
        } 
   else {
         setPhoneNumber_errorMessage ("");
        }
};

  const handleFirstnameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    setFirstname ( e.target.value )
	
    if ( e.target.value !== "" )
          {
           setFirstname_errorMessage ("")
          }
     else {
           setFirstname_errorMessage ("La saisie est obligatoire")
          }
  }

  const handleLastnameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    setLastname ( e.target.value )
	
    if ( e.target.value !== "" )
          {
           setLastname_errorMessage ("")
          }
     else {
           setLastname_errorMessage ("La saisie est obligatoire")
          }
  }

  const handleAgeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    const intValue = parseInt ( e.target.value , 10 );

    setAge ( e.target.value )

    if ( isNaN(intValue) )
          {
           setAge_errorMessage ("La saisie est incorrecte")
          }
     else {
           setAge_errorMessage ("")
          }
  }

  const handleCityChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    setCity ( e.target.value )
	
    if ( e.target.value !== "" )
          {
           setCity_errorMessage ("")
          }
     else {
           setCity_errorMessage ("La saisie est obligatoire")
          }
  }

  const handleCancelButtonClick = () => {

    if ( typeof (onClickOnCancelButton) !== "undefined" )
	 {
      onClickOnCancelButton ();
	 }
  };

  const handleValidButtonClick = () => {

    if ( typeof (onClickOnValidButton) !== "undefined" )
	 {
      if ( email_errorMessage       === "" && 
	       phoneNumber_errorMessage === "" && 
	       firstname_errorMessage   === "" && 
	       lastname_errorMessage    === "" && 
	       age_errorMessage         === "" && 
		   city_errorMessage        === "" )
       {
        const intValue = parseInt ( age , 10 );

        onClickOnValidButton ( firstname, lastname, intValue, email, phoneNumber, city );
       }
	 }
  };

  const generateClassName = (): string => {
  
        if ( typeof ( messageType ) === "undefined" ) { return classNames(styles.WhiteMessage); }
   else if (          messageType   === "normal"    ) { return classNames(styles.WhiteMessage); }
   else if (          messageType   === "alert"     ) { return classNames(styles.RedMessage  ); }
   else if (          messageType   === "ack"       ) { return classNames(styles.GreenMessage); }
   else                                               { return classNames(styles.WhiteMessage); }
  }

  return (
            <div className= {classNames(styles.window)}>

              <div className = {classNames(generateClassName (),styles.message)}>{message}</div>

              <br/>

              <WidgetFrame name               = "Prénom"
                           error              = { firstname_errorMessage }
					       labelTextPosition  = { isSmallScreen ? "left" : "left" }
						   labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
						   classNameName      = { styles.frameLabel }
   						   classNameGrid      = { stylesGridInfo } >

			         <Field type           = "text"
                            placeholder    = ""
                            value          = {firstname}
                            onChange       = {handleFirstnameChange} />
                         
              </WidgetFrame>

              <div className={styles.interzone}>&nbsp;</div>

              <WidgetFrame name               = "Nom"
                           error              = { lastname_errorMessage }
					       labelTextPosition  = { isSmallScreen ? "left" : "left" }
						   labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
						   classNameName      = { styles.frameLabel }
   						   classNameGrid      = { stylesGridInfo } >

			         <Field type           = "text"
                            placeholder    = ""
                            value          = {lastname}
                            onChange       = {handleLastnameChange} />

              </WidgetFrame>

              <div className={styles.interzone}>&nbsp;</div>

              <WidgetFrame name               = "Age"
                           error              = { age_errorMessage }
					       labelTextPosition  = { isSmallScreen ? "left" : "left" }
						   labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
						   classNameName      = { styles.frameLabel }
   						   classNameGrid      = { stylesGridInfo } >

			         <Field type           = "text"
                            placeholder    = ""
                            value          = {age}
                            onChange       = {handleAgeChange} />

              </WidgetFrame>

              <div className={styles.interzone}>&nbsp;</div>

              <WidgetFrame name               = "Email"
                           error              = { email_errorMessage }
					       labelTextPosition  = { isSmallScreen ? "left" : "left" }
						   labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
						   classNameName      = { styles.frameLabel }
   						   classNameGrid      = { stylesGridInfo } >

			         <Field type           = "email"
                            placeholder    = ""
                            value          = {email}
                            onChange       = {handleEmailChange} />

              </WidgetFrame>

              <div className={styles.interzone}>&nbsp;</div>

              <WidgetFrame name               = "Numéro de téléphone"
                           error              = { phoneNumber_errorMessage }
					       labelTextPosition  = { isSmallScreen ? "left" : "left" }
						   labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
						   classNameName      = { styles.frameLabel }
   						   classNameGrid      = { stylesGridInfo } >

			         <Field type           = "email"
                            placeholder    = ""
                            value          = {phoneNumber}
                            onChange       = {handlePhoneNumberChange} />

              </WidgetFrame>

              <div className={styles.interzone}>&nbsp;</div>

              <WidgetFrame name               = "Ville"
                           error              = { city_errorMessage }
					       labelTextPosition  = { isSmallScreen ? "left" : "left" }
						   labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
						   classNameName      = { styles.frameLabel }
   						   classNameGrid      = { stylesGridInfo } >

			         <Field type           = "text"
                            placeholder    = ""
                            value          = {city}
                            onChange       = {handleCityChange} />

              </WidgetFrame>

              <br/>

              <div className={classNames(styles.buttons_block)}>
               
              {
			    ( typeof (onClickOnCancelButton) !== "undefined" ) &&
                (				

                        <button className = "mx-5 px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleCancelButtonClick}>
                           { typeof (cancelButtonName) === "undefined" ? "Annuler" : cancelButtonName }
                        </button>

				)
			  }

              {
			    ( typeof (onClickOnValidButton) !== "undefined" ) &&
                (				
                        <button className = "mx-5 px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleValidButtonClick}>
                          { typeof (validButtonName) === "undefined" ? "Valider" : validButtonName }
                        </button>
						
				)
			  }

			 </div>

            </div>
         );
};

export default WindowWithForm;
